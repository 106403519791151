import { atom } from "jotai";

import { type Account } from "shared/apiClient";
import { GroupType } from "shared/types";
import { atomWithLocalStorage } from "@/lib/jotai";

export enum AuthFlow {
  Login = "Login",
  ForgotPassword = "ForgotPassword",
  NewPassword = "NewPassword",
  ResetPassword = "ResetPassword",
}

export const authFlowSelectedVar = atom<AuthFlow>(AuthFlow.Login);

export const isAuthenticatedVar = atom<boolean>(false);

export const accountVar = atom<Account | null>(null);
export const isAdminVar = atom(
  (get) =>
    get(accountVar)?.groups?.some(
      (group) => group?.name.toUpperCase() === GroupType.ADMIN,
    ) ?? false,
);

export const shouldReloadConversationsListVar = atom(false);

export type ExpertEditorConfig = {
  lineWrapEnabled?: boolean;
  theme?: string;
};

export const expertEditorConfigVar = atomWithLocalStorage<ExpertEditorConfig>(
  "expert-editor-config",
  {},
);

export const selectedNodeIdsVar = atom<Array<string>>([]);
export const selectedEdgeIdsVar = atom<Array<string>>([]);
