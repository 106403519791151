import { useState, type ReactNode } from "react";
import { useAtomValue } from "jotai";
import { ReactFlowProvider } from "@xyflow/react";

import { ChannelProvider } from "shared/channel";
import { ApiProvider } from "./ApiProvider";
import { ThemeProvider } from "@/components/ThemeProvider";
import { MyContext } from "@/contexts/AppContext";
import { isAuthenticatedVar } from "@/variables";

const channelUrl = import.meta.env.VITE_REACT_APP_CHANNEL_ENDPOINT;

export interface ProvidersProps {
  children: ReactNode;
}

export function Providers({ children }: ProvidersProps) {
  const isAuthenticated = useAtomValue(isAuthenticatedVar);

  const [demo, setDemo] = useState(false);

  return (
    <ApiProvider>
      <ChannelProvider
        channelUrl={channelUrl}
        isAuthenticated={isAuthenticated}
      >
        <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
          <MyContext.Provider value={{ demo, setDemo }}>
            <ReactFlowProvider>{children}</ReactFlowProvider>
          </MyContext.Provider>
        </ThemeProvider>
      </ChannelProvider>
    </ApiProvider>
  );
}
